import * as React from 'react';
import firebaseConfig from './firebaseconfig';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Card from '@mui/material/Card';
import newShipment from './newShipment.png';
import { CardHeader, Avatar, FormControl, TextField, Button, CardContent, IconButton, FormHelperText, Snackbar, Alert, Typography, Dialog, InputAdornment, CardActions } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Drawer from '@mui/material/Drawer';
import CalculateIcon from '@mui/icons-material/Calculate';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTheme } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import LoadingAnimations from './loadingAnimations';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';




function getStyles(name, multipleAccessorials, theme) {
    return {
        fontWeight:
            multipleAccessorials.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function sleep1(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

// function sleep2(delay = 0) {
//     return new Promise((resolve) => {
//         setTimeout(resolve, delay);
//     });
// }


// const useStyles = makeStyles({
//     input: {
//         "&:disabled": {
//           color: "green"
//         }
//       }
// });


const styles = makeStyles({
    // root: {
    //   "& input.Mui-disabled": {
    //     color: "green"
    //   }
    // }
    root: {
        '& $disabled $notchedOutline': {
            borderColor: 'orange'
        }
    },
    disabled: {},
    notchedOutline: {}
});





export default function Body() {
    const classes = styles;
    let navigate = useNavigate();
    //for autocomplete1
    const [openAuto1, setOpenAuto1] = React.useState(false);
    const [sendingDataToSalesforce, setSendingDataToSalesforce] = React.useState(false);
    const [sendingDataToSalesforce2, setSendingDataToSalesforce2] = React.useState(false);
    const [autoOptions1, setAutoOptions1] = React.useState([]);
    const autoComplete1Loading = openAuto1 && autoOptions1.length === 0;
    const [autoComplete2Loading, setAutoComplete2Loading] = React.useState(false);
    const [autoComplete3Loading, setAutoComplete3Loading] = React.useState(false);
    // const plantCodes = locationData['default'];
    const [plantCodes, setPlantCodes] = React.useState([]);

    const fetchData = async () => {
        try {
            console.log("waiting for fetch data")
            await axios.get("https://tmsfirst-tender.herokuapp.com/plantlocation").then((response)=>{
                if(response){
                    console.log("response: ", response)
                    setPlantCodes(response.data)
                }
            })


            // const db = getFirestore(); 
            // const collectionRef = collection(db, "VendorLocations");

            // const querySnapshot = await getDocs(collectionRef);
            // const data = querySnapshot.docs.map((doc) => doc.data());
            // setPlantCodes(data);
        } catch (error) {
            console.log("Error getting documents:", error);
        }
    };
    React.useEffect(() => {
        console.log('useEffect triggered with count:');
        fetchData();
    }, []);
    const [shipId, setShipId] = React.useState(null);
    const [shipmentName, setShipmentName] = React.useState('');
    const [isShipmentRestricted, setRestriction] = React.useState(false);
    const [headerMsg, setHeaderMsg] = React.useState('Please wait while we fetch carrier status...');
    // const headObj = {__html:headerMsg}
    const [selectedDate, setSelectedDate] = React.useState(new Date());

    const handleDateChange = (date) => {
        console.log(date);
        setSelectedDate(date);
    };
    // const [destinationZipcodeError, setDestinationZipcodeError] = React.useState(new Date());


    React.useEffect(() => {

        let redirect = window.sessionStorage.getItem('needToRedirect');
        console.log('redirect: ', redirect);
        //for blocking shipments with specific destination zipcodes eg: 77041
        let blockShipment = window.sessionStorage.getItem('blockShipment')

        if (blockShipment === 'true') {
            console.log("Shipment block is true")
            console.log("From redirect false");
            let savedShipmentData = window.sessionStorage.getItem('shipmentInfo');
            let tempShipData = JSON.parse(savedShipmentData);
            console.log(tempShipData);
            // formik1.values.emailAddress = ;
            // formik1.values.salesOrder = savedShipmentData['salesOrder'];
            // formik1.setFieldValue('emailAddress', savedShipmentData['emailAddress']);
            setPackage1Type(tempShipData['shipmentData']['packageType1']);
            formik1.values['packageType1'] = tempShipData['shipmentData']['packageType1'];
            setLoadsData(tempShipData['loads']);
            let shipmentId = window.sessionStorage.getItem('ship_id');
            setShipId(shipmentId);
            setShipmentName(window.sessionStorage.getItem('shipmentName'));
            setRestriction(true)
            
            setHeaderMsg('Your shipping request has been recorded successfully.</br> We are currently consolidating shipments to the Northwest region to optimize our logistics routing.')
            if (tempShipData['shipmentData']['accessorials'] !== '') {
                setMultipleAccessorials(tempShipData['shipmentData']['accessorials'].split(';'));
            }

            formik1.values['emailAddress'] = tempShipData['shipmentData']['emailAddress'];
            formik1.values['purchaseOrder'] = tempShipData['shipmentData']['purchaseOrder'];
            formik1.values['salesOrder'] = tempShipData['shipmentData']['salesOrder'];
            formik1.values['shipperName'] = tempShipData['shipmentData']['shipperName'];
            formik1.values['origin'] = tempShipData['shipmentData']['origin'];
            formik1.values['originAddressLine1'] = tempShipData['shipmentData']['originAddressLine1'];
            formik1.values['originAddressLine2'] = tempShipData['shipmentData']['originAddressLine2'];
            formik1.values['originZipcode'] = tempShipData['shipmentData']['originZipcode'];
            formik1.values['originCity'] = tempShipData['shipmentData']['originCity'];
            formik1.values['originState'] = tempShipData['shipmentData']['originState'];
            formik1.values['originContact'] = tempShipData['shipmentData']['originContact'];
            formik1.values['originPhone'] = tempShipData['shipmentData']['originPhone'];
            formik1.values['originNotes'] = tempShipData['shipmentData']['originNotes'];
            formik1.values['dnowPlantCode'] = tempShipData['shipmentData']['dnowPlantCode'];
            formik1.values['destination'] = tempShipData['shipmentData']['destination'];
            formik1.values['destinationAddressLine1'] = tempShipData['shipmentData']['destinationAddressLine1'];
            formik1.values['destinationAddressLine2'] = tempShipData['shipmentData']['destinationAddressLine2'];
            formik1.values['destinationZipcode'] = tempShipData['shipmentData']['destinationZipcode'];
            formik1.values['destinationCity'] = tempShipData['shipmentData']['destinationCity'];
            formik1.values['destinationState'] = tempShipData['shipmentData']['destinationState'];
            formik1.values['destinationPhone'] = tempShipData['shipmentData']['destinationPhone'];
            formik1.values['destinationContact'] = tempShipData['shipmentData']['destinationContact'];
            formik1.values['destinationNotes'] = tempShipData['shipmentData']['destinationNotes'];
            formik1.values['thu'] = tempShipData['shipmentData']['thu'];
            formik1.values['trailerSpaceRequired'] = tempShipData['shipmentData']['trailerSpaceRequired'];
            formik1.values['pickUpDate'] = tempShipData['shipmentData']['pickUpDate'].replace(':00.000+0000', '');
            formik1.values['deliveryDate'] = tempShipData['shipmentData']['deliveryDate'].replace(':00.000+0000', '');
            formik1.values['stackable'] = tempShipData['shipmentData']['stackable'];
        }
        else {
            if (redirect === 'true') {
                console.log("inside redirect")
                let path = `/details`;
                navigate(path);
            }
            else if (redirect === 'false') {
                console.log("From redirect false");
                let savedShipmentData = window.sessionStorage.getItem('shipmentInfo');
                let tempShipData = JSON.parse(savedShipmentData);
                console.log(tempShipData);
                // formik1.values.emailAddress = ;
                // formik1.values.salesOrder = savedShipmentData['salesOrder'];
                // formik1.setFieldValue('emailAddress', savedShipmentData['emailAddress']);
                setPackage1Type(tempShipData['shipmentData']['packageType1']);
                formik1.values['packageType1'] = tempShipData['shipmentData']['packageType1'];
                setLoadsData(tempShipData['loads']);
                let shipmentId = window.sessionStorage.getItem('ship_id');
                setShipId(shipmentId);
                setShipmentName(window.sessionStorage.getItem('shipmentName'));
                if (tempShipData['shipmentData']['accessorials'] !== '') {
                    setMultipleAccessorials(tempShipData['shipmentData']['accessorials'].split(';'));
                }

                formik1.values['emailAddress'] = tempShipData['shipmentData']['emailAddress'];
                formik1.values['purchaseOrder'] = tempShipData['shipmentData']['purchaseOrder'];
                formik1.values['salesOrder'] = tempShipData['shipmentData']['salesOrder'];
                formik1.values['shipperName'] = tempShipData['shipmentData']['shipperName'];
                formik1.values['origin'] = tempShipData['shipmentData']['origin'];
                formik1.values['originAddressLine1'] = tempShipData['shipmentData']['originAddressLine1'];
                formik1.values['originAddressLine2'] = tempShipData['shipmentData']['originAddressLine2'];
                formik1.values['originZipcode'] = tempShipData['shipmentData']['originZipcode'];
                formik1.values['originCity'] = tempShipData['shipmentData']['originCity'];
                formik1.values['originState'] = tempShipData['shipmentData']['originState'];
                formik1.values['originContact'] = tempShipData['shipmentData']['originContact'];
                formik1.values['originPhone'] = tempShipData['shipmentData']['originPhone'];
                formik1.values['originNotes'] = tempShipData['shipmentData']['originNotes'];
                formik1.values['dnowPlantCode'] = tempShipData['shipmentData']['dnowPlantCode'];
                formik1.values['destination'] = tempShipData['shipmentData']['destination'];
                formik1.values['destinationAddressLine1'] = tempShipData['shipmentData']['destinationAddressLine1'];
                formik1.values['destinationAddressLine2'] = tempShipData['shipmentData']['destinationAddressLine2'];
                formik1.values['destinationZipcode'] = tempShipData['shipmentData']['destinationZipcode'];
                formik1.values['destinationCity'] = tempShipData['shipmentData']['destinationCity'];
                formik1.values['destinationState'] = tempShipData['shipmentData']['destinationState'];
                formik1.values['destinationPhone'] = tempShipData['shipmentData']['destinationPhone'];
                formik1.values['destinationContact'] = tempShipData['shipmentData']['destinationContact'];
                formik1.values['destinationNotes'] = tempShipData['shipmentData']['destinationNotes'];
                formik1.values['thu'] = tempShipData['shipmentData']['thu'];
                formik1.values['trailerSpaceRequired'] = tempShipData['shipmentData']['trailerSpaceRequired'];
                formik1.values['pickUpDate'] = tempShipData['shipmentData']['pickUpDate'].replace(':00.000+0000', '');
                formik1.values['deliveryDate'] = tempShipData['shipmentData']['deliveryDate'].replace(':00.000+0000', '');
                formik1.values['stackable'] = tempShipData['shipmentData']['stackable'];

                setTimeout(() => {
                    axios.post("https://tmsfirst-tender.herokuapp.com/api/triggerSFtoSendMail", { 'shipmentId': shipmentId }).then((response) => {
                        setHeaderMsg(response['data']['Message']);

                    }).catch(err => {
                        setHeaderMsg(err.toString());

                    });
                }, 6000);
            }
        }



        // setShipId(window.sessionStorage.getItem('ship_id'));


    }, [])
    React.useEffect(() => {
        let autoOptions1Active = true;

        if (!autoComplete1Loading) {
            return undefined;
        }

        (async () => {
            await sleep1(1e3);

            if (autoOptions1Active) {
                setAutoOptions1([...plantCodes]);
            }
        })();

        return () => {
            autoOptions1Active = false;
        };
    }, [autoComplete1Loading]);

    React.useEffect(() => {
        if (!openAuto1) {
            setAutoOptions1([]);
        }
    }, [openAuto1]);

    React.useEffect(() => {
        if (loadsData.length > 0) {
            let tWeight = 0;
            for (let i = 0; i < loadsData.length; i++) {
                tWeight = tWeight + loadsData[i]['weight'];
            }
            setLoadsWeight(tWeight);
        }

    })



    //Other main state constants
    const [ValidationFailure, setValidationFailure] = React.useState({ 'error': false, 'message': '' })
    const [classCalculateFailure, setclassCalculateFailure] = React.useState(false);
    const [packageType1, setPackage1Type] = React.useState('');
    const [packageType2, setPackage2Type] = React.useState('');
    const [classs, setClasss] = React.useState('');
    const [units, setUnits] = React.useState('in');
    const [state, setState] = React.useState({
        bottom: false,
    });
    const [manualIntevention, setManualIntervention] = React.useState(false);
    const [manualIntevention2, setManualIntervention2] = React.useState(false);
    const [editMode, setEditMode] = React.useState({ 'status': false, 'idx': null });
    const [loadsData, setLoadsData] = React.useState([
        // {
        //     'productDescription': '',
        //     'qty': 0,
        //     'length': 0,
        //     'width': 0,
        //     'height': 0,
        //     'units': 'in',
        //     'class': '--None--',
        //     'packageType2': '--None--',
        //     'weight': 0,
        //     'comments': ''
        // },

    ]);
    const [loadsWeight, setLoadsWeight] = React.useState(0);
    const removeRow = (e, idx) => {
        const copyRows = [...loadsData];
        // console.log(copyRows[idx], idx);
        copyRows.splice(idx, 1);
        setLoadsData(copyRows)
        // console.log(copyRows);
    }
    const editRow = (e, idx) => {
        setEditMode({ 'status': true, 'idx': idx });
        console.log(loadsData[idx], idx);
        setState({ bottom: true });
        formik2.values['productDescription'] = loadsData[idx]['productDescription'];
        formik2.values['qty'] = loadsData[idx]['qty'];
        formik2.values['length'] = loadsData[idx]['length'];
        formik2.values['width'] = loadsData[idx]['width'];
        formik2.values['height'] = loadsData[idx]['height'];
        formik2.values['weight'] = loadsData[idx]['weight'];
        formik2.values['comments'] = loadsData[idx]['comments'];
        formik2.values['units'] = loadsData[idx]['units'];
        formik2.values['classs'] = loadsData[idx]['classs'];
        formik2.values['packageType2'] = loadsData[idx]['packageType2'];
        setUnits(loadsData[idx]['units']);
        setClasss(loadsData[idx]['classs']);
        setPackage2Type(loadsData[idx]['packageType2']);
    }


    const validateSchema1 = Yup.object({
        emailAddress: Yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required'),//^[55,25,65,66,1,266]
        purchaseOrder: Yup.string().required('Please enter PO Number').matches(/^(55|25|65|66|1|265|266|267)/, 'Should begin with "55" for stock transfer, "25, 65, 66, 265, 266 or 267" for Purchase order or "1" for Sales order').max(255, 'Purchase order must not be more than 255 characters'),
        origin: Yup.string().required('Please enter Shipper Name'),
        pickUpDate: Yup.date()
            .required('Pickup date is mandatory')
            .min(new Date(Date.now() - 86400000), "Pickup Date is not valid"),
        destination: Yup.string().required('Please enter destination').max(135, 'Destination must not be more than 135 characters'),
        deliveryDate: Yup.date()
            // .required('Delivery date is mandatory')
            .min(Yup.ref('pickUpDate'), "Delivery Date need to be greater than Pickup date"),
        originAddressLine1: Yup.string().required('Please enter address').max(55, 'Address must not be more than 55 characters'),
        destinationAddressLine1: Yup.string().required('Please enter address').max(55, 'Address must not be more than 55 characters'),
        originAddressLine2: Yup.string().max(55, 'Address must not be more than 55 characters'),
        destinationAddressLine2: Yup.string().max(55, 'Address must not be more than 55 characters'),
        originZipcode: Yup.string().required('Please enter zipcode'),
        originCity: Yup.string().required('Please enter city').max(55, 'City must not be more than 55 characters'),
        originState: Yup.string().required('Please enter state').max(2, 'State must not be more than 2 characters'),
        originPhone: Yup.string().required('Please enter phone').max(16, 'Phone must not be more than 16 characters'),
        originContact: Yup.string().required('Please enter contact').max(50, 'Contact must not be more than 50 characters'),
        destinationZipcode: Yup.string().required('Please enter zipcode'),
        originNotes: Yup.string().max(255, 'Notes must not be more than 255 characters'),
        destinationNotes: Yup.string().max(255, 'Notes must not be more than 255 characters'),
        destinationCity: Yup.string().required('Please enter city').max(55, 'City must not be more than 55 characters'),
        destinationState: Yup.string().required('Please enter state').max(2, 'State must not be more than 2 characters'),
        destinationPhone: Yup.string().required('Please enter phone').max(16, 'Phone must not be more than 16 characters'),
        destinationContact: Yup.string().required('Please enter contact').max(50, 'Contact must not be more than 50 characters'),
        thu: Yup.number().required("Please enter THU's").positive("Package count cannot be negative").integer("Invalid THU count").max(9999999999, 'Cannot be more than 10 characters'),
        packageType1: Yup.string().required('Please select the package type')
    });


    const validateSchema2 = Yup.object({
        productDescription: Yup.string('Enter the product description').required('Please enter product description').max(255, 'Cannot be more than 255 characters'),
        qty: Yup.number('Quantity needs to be number').min(1, 'Invalid quantity entered').required('Enter quantity'),
        length: Yup.number('Length needs to be number').min(1, 'Invalid length entered'),
        width: Yup.number('Width needs to be number').min(1, 'Invalid width entered'),
        height: Yup.number('Height needs to be number').min(1, 'Invalid height entered'),
        units: Yup.string().required('Please select units'),
        classs: Yup.string().required('Please select the class'),
        packageType2: Yup.string().required('Please select the package type'),
        weight: Yup.number('Weight needs to be number').min(1, 'Invalid weight entered').required('Please enter weight'),
        comments: Yup.string().max(1200, 'Please limit comments')
    });

    // const classes = useStyles();





    const toggleDrawer = (anchor, open, save) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        formik2.values['productDescription'] = '';
        formik2.values['qty'] = '';
        formik2.values['length'] = '';
        formik2.values['width'] = '';
        formik2.values['height'] = '';
        formik2.values['weight'] = '';
        formik2.values['comments'] = '';
        setUnits('in');
        setClasss('');
        setPackage2Type('');
        setState({ ...state, [anchor]: open });
    };



    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 0;
    const MenuProps = {
        PaperProps: {
            style: {
                // top: '0',
                // top: '350px',
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                //   width: 150,

            },
        },
    };

    const accessorialss = [
        'Construction Site Delivery',
        'Excessive Length',
        'Inside Delivery',
        'Inside Pickup',
        'Non-Commercial',
        'Residential Delivery',
        'Residential Pickup',
        'Sort and Segregate',
        'Appointment',
        'Lift Gate Delivery',
        'Lift Gate Pickup'
    ];

    const theme = useTheme();
    const [multipleAccessorials, setMultipleAccessorials] = React.useState([]);

    const handleAccesorialChange = (event) => {
        const {
            target: { value },
        } = event;
        setMultipleAccessorials(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );

    };




    const list = (anchor) => (
        <form onSubmit={(e) => {
            e.preventDefault();
            // formik2.handleSubmit();
        }} noValidate>
            <Box
                sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250, backgroundColor: '#EDEDED', whiteSpace: 'nowrap' }}
                role="presentation"
            >
                <IconButton sx={{ float: 'right' }} onClick={toggleDrawer(anchor, false, false)}>
                    <CloseRoundedIcon></CloseRoundedIcon>
                </IconButton>

                <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                    <div>
                        <Box sx={{ display: 'flex', flexWrap: 'nowrap', whiteSpace: 'nowrap' }}>
                            <Box sx={{ m: '1rem 1rem 0rem 1rem', width: '100%' }}>
                                <Card sx={{ borderRadius: '0.5rem' }}>
                                    <CardContent sx={{ display: 'flex', justifyContent: 'space-evenly', flexDirection: 'row' }}>

                                        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>

                                            <TextField sx={{ m: 1, width: '80%' }} id={'productDescription'} label="Product Description" name={'productDescription'} autoFocus={true} autoComplete={'off'} value={formik2.values.productDescription} onChange={formik2.handleChange} error={formik2.touched.productDescription && Boolean(formik2.errors.productDescription)} helperText={formik2.touched.productDescription && formik2.errors.productDescription} required variant="outlined" size='small' />

                                            <FormControl sx={{ m: 1, width: '70%' }} size='small' error={Boolean(formik2.errors.packageType2)}>
                                                <InputLabel id={"packageType2Label"}>Package Type</InputLabel>
                                                <Select
                                                    labelId="packageType2Label"
                                                    id="packageType2"
                                                    value={packageType2}
                                                    label="PackageType2"
                                                    required
                                                    // sx={{height: '600px', bottom: '250px'}}
                                                    MenuProps={{ PaperProps: { sx: { maxHeight: '600px' } } }}
                                                    onChange={
                                                        e => {
                                                            setPackage2Type(e['target'].value);
                                                            formik2.values.packageType2 = e['target'].value;
                                                        }
                                                    }
                                                >
                                                    <MenuItem value={'Bags'}>Bags</MenuItem>
                                                    <MenuItem value={'Barrels'}>Barrels</MenuItem>
                                                    <MenuItem value={'Baskets'}>Baskets</MenuItem>
                                                    <MenuItem value={'Boxes'}>Boxes</MenuItem>
                                                    <MenuItem value={'Buckets'}>Buckets</MenuItem>
                                                    <MenuItem value={'Bundles'}>Bundles</MenuItem>
                                                    <MenuItem value={'BXSKID_LG'}>BXSKID_LG</MenuItem>
                                                    <MenuItem value={'BXSKID_MD'}>BXSKID_MD</MenuItem>
                                                    <MenuItem value={'BXSKID SM'}>BXSKID SM</MenuItem>
                                                    <MenuItem value={'Cage'}>Cage</MenuItem>
                                                    <MenuItem value={'Cartons'}>Cartons</MenuItem>
                                                    <MenuItem value={'Cases'}>Cases</MenuItem>
                                                    <MenuItem value={'Container-POD'}>Container-POD</MenuItem>
                                                    <MenuItem value={'Crates'}>Crates</MenuItem>
                                                    <MenuItem value={'Cylinder'}>Cylinder</MenuItem>
                                                    <MenuItem value={'Cylinders'}>Cylinders</MenuItem>
                                                    <MenuItem value={'Envelopes'}>Envelopes</MenuItem>
                                                    <MenuItem value={'Feet'}>Feet</MenuItem>
                                                    <MenuItem value={'Fiberboard Box'}>Fiberboard Box</MenuItem>
                                                    <MenuItem value={'Fiber Drum'}>Fiber Drum</MenuItem>
                                                    <MenuItem value={'Gaylord Boxes'}>Gaylord Boxes</MenuItem>
                                                    <MenuItem value={'Joints'}>Joints</MenuItem>
                                                    <MenuItem value={'Pails'}>Pails</MenuItem>
                                                    <MenuItem value={'Pallets'}>Pallets</MenuItem>
                                                    <MenuItem value={'Pieces'}>Pieces</MenuItem>
                                                    <MenuItem value={'Pipes'}>Pipes</MenuItem>
                                                    <MenuItem value={'Plastic Drum'}>Plastic Drum</MenuItem>
                                                    <MenuItem value={'Plastic Jerrican'}>Plastic Jerrican</MenuItem>
                                                    <MenuItem value={'Plywood Box'}>Plywood Box</MenuItem>
                                                    <MenuItem value={'Reels'}>Reels</MenuItem>
                                                    <MenuItem value={'Skids'}>Skids</MenuItem>
                                                    <MenuItem value={'Steel Drum'}>Steel Drum</MenuItem>
                                                    <MenuItem value={'Totes'}>Totes</MenuItem>
                                                    <MenuItem value={'Tubes'}>Tubes</MenuItem>
                                                    <MenuItem value={'Trailer'}>Trailer</MenuItem>
                                                </Select>
                                                <FormHelperText>{formik2.errors.packageType2}</FormHelperText>

                                            </FormControl>

                                            <TextField sx={{ m: 1, width: '20%' }} id={'qty'} label="Qty" variant="outlined" name={'qty'} type={'number'} autoComplete={'off'} required size='small' value={formik2.values.qty} onChange={formik2.handleChange} error={formik2.touched.qty && Boolean(formik2.errors.qty)} helperText={formik2.touched.qty && formik2.errors.qty} />
                                            <TextField sx={{ m: 1, width: '15%' }} id={'length'} label="L" type={'number'} variant="outlined" size='small' autoComplete={'off'} value={formik2.values.length} onChange={formik2.handleChange} error={formik2.touched.length && Boolean(formik2.errors.length)} helperText={formik2.touched.length && formik2.errors.length} />
                                            <TextField sx={{ m: 1, width: '15%' }} id={'width'} label="W" type={'number'} autoComplete={'off'} variant="outlined" size='small' value={formik2.values.width} onChange={formik2.handleChange} error={formik2.touched.width && Boolean(formik2.errors.width)} helperText={formik2.touched.width && formik2.errors.width} />
                                            <TextField sx={{ m: 1, width: '15%' }} id={'height'} label="H" type={'number'} autoComplete={'off'} variant="outlined" size='small' value={formik2.values.height} onChange={formik2.handleChange} error={formik2.touched.height && Boolean(formik2.errors.height)} helperText={formik2.touched.height && formik2.errors.height} />
                                            <FormControl sx={{ m: 1, width: '22%' }} size='small' error={Boolean(formik2.errors.units)}>
                                                <InputLabel id="unitsLabel">Units</InputLabel>
                                                <Select
                                                    // defaultValue='in'
                                                    labelId="unitsLabel"
                                                    id="units"
                                                    value={units}
                                                    required
                                                    label="Units"
                                                    onChange={
                                                        e => {
                                                            setUnits(e['target'].value);
                                                            formik2.values.units = e['target'].value;

                                                        }
                                                    }
                                                >
                                                    <MenuItem value={'in'}>In</MenuItem>
                                                    <MenuItem value={'ft'}>Ft</MenuItem>
                                                </Select>
                                                <FormHelperText>{formik2.errors.units}</FormHelperText>
                                            </FormControl>

                                            <Snackbar open={classCalculateFailure} autoHideDuration={6000} onClose={(event, reason) => {
                                                if (reason === 'clickaway') {
                                                    return;
                                                }

                                                setclassCalculateFailure(false);
                                            }}>
                                                <Alert severity="error" sx={{ width: '100%' }}>
                                                    Invalid Dimensions Entered
                                                </Alert>
                                            </Snackbar>
                                            <TextField sx={{ m: 1, width: '45%' }} id={"weight"} label="Weight (lbs)" autoComplete={'off'} type='number' variant="outlined" size='small' value={formik2.values.weight} onChange={formik2.handleChange} error={formik2.touched.weight && Boolean(formik2.errors.weight)} helperText={formik2.touched.weight && formik2.errors.weight} required />

                                            <IconButton aria-label="calculate" onClick={() => {


                                                let l = formik2.values.length;
                                                let w = formik2.values.width;
                                                let h = formik2.values.height;
                                                let u = formik2.values.units;
                                                let wt = formik2.values.weight;

                                                console.log(l, w, h, u, wt)

                                                if (
                                                    l !== undefined && w !== undefined && h !== undefined && u !== undefined && wt !== undefined
                                                    &&
                                                    l !== 0 && w !== 0 && h !== 0 && wt !== 0 && l !== '' && w !== '' && h !== '' && wt !== ''
                                                ) {
                                                    try {
                                                        let volume = l * w * h;
                                                        if (u !== 'ft')
                                                            volume = volume / 1728;

                                                        let density = Number(wt / volume);
                                                        let classRange = [{ highRange: 0.99999, class: 500 }, { highRange: 1, class: 400 }, { highRange: 2, class: 300 }, { highRange: 3, class: 250 }, { highRange: 4, class: 200 }
                                                            , { highRange: 5, class: 175 }, { highRange: 6, class: 150 }, { highRange: 7, class: 125 }, { highRange: 8, class: 110 }
                                                            , { highRange: 9, class: 100 }, { highRange: 10, class: 92.5 }, { highRange: 12, class: 85 }
                                                            , { highRange: 13, class: 77.5 }, { highRange: 15, class: 70 }, { highRange: 22, class: 65 }, { highRange: 30, class: 60 }, { highRange: 35, class: 55 }, { highRange: 50, class: 50 }];
                                                        let frtClass = getClosest(classRange, density, 'highRange')['class'];
                                                        if (isNaN(frtClass)) {
                                                            setclassCalculateFailure(true);
                                                        }
                                                        else {
                                                            setClasss(frtClass);
                                                            formik2.values.classs = frtClass;
                                                            // let tempLoadsData = [...loadsData];
                                                            // tempLoadsData['class'] = frtClass;
                                                            // setLoadsData(tempLoadsData);

                                                            // toggleDrawer(anchor, false);
                                                            // toggleDrawer('bottom', true);

                                                        }
                                                    }
                                                    catch (e) {
                                                        setclassCalculateFailure(true);
                                                    }

                                                }
                                                else {
                                                    setclassCalculateFailure(true);

                                                }


                                                function getClosest(data, x, param) {
                                                    for (var i = 0; i < data.length; i++) {
                                                        if (x < data[i][param]) break
                                                    }
                                                    if (i === data.length) return data[i - 1];
                                                    if (i === 0) return data[0];
                                                    return data[i - 1];
                                                    //return data[i + +(x > ((data[i][param] + data[i + 1][param]) / 2))];
                                                }

                                            }} sx={{ width: '3%' }}>
                                                <CalculateIcon />
                                            </IconButton>

                                            <FormControl sx={{ m: 1, width: '30%' }} size='small' error={Boolean(formik2.errors.classs)}>
                                                <InputLabel id="classsLabel">Class</InputLabel>
                                                <Select
                                                    labelId="classsLabel"
                                                    id="classs"
                                                    required
                                                    value={classs}
                                                    onChange={
                                                        e => {
                                                            setClasss(e['target'].value);
                                                            formik2.values.classs = e['target'].value;
                                                        }
                                                    }
                                                    label="Class"
                                                >
                                                    {/* <MenuItem value={'--None--'}>--None--</MenuItem> */}
                                                    <MenuItem value={50}>50</MenuItem>
                                                    <MenuItem value={55}>55</MenuItem>
                                                    <MenuItem value={60}>60</MenuItem>
                                                    <MenuItem value={65}>65</MenuItem>
                                                    <MenuItem value={70}>70</MenuItem>
                                                    <MenuItem value={77.5}>77.5</MenuItem>
                                                    <MenuItem value={85}>85</MenuItem>
                                                    <MenuItem value={92.5}>92.5</MenuItem>
                                                    <MenuItem value={100}>100</MenuItem>
                                                    <MenuItem value={110}>110</MenuItem>
                                                    <MenuItem value={125}>125</MenuItem>
                                                    <MenuItem value={150}>150</MenuItem>
                                                    <MenuItem value={175}>175</MenuItem>
                                                    <MenuItem value={200}>200</MenuItem>
                                                    <MenuItem value={250}>250</MenuItem>
                                                    <MenuItem value={300}>300</MenuItem>
                                                    <MenuItem value={400}>400</MenuItem>
                                                    <MenuItem value={500}>500</MenuItem>
                                                    <MenuItem value={'NA'}>NA</MenuItem>
                                                </Select>
                                                <FormHelperText>{formik2.errors.classs}</FormHelperText>
                                            </FormControl>

                                            <TextField sx={{ m: 1, width: '100%' }} id={"comments"} autoComplete={'off'} label="Comments" variant="outlined" size='small' value={formik2.values.comments} onChange={formik2.handleChange} error={formik2.touched.comments && Boolean(formik2.errors.comments)} helperText={formik2.touched.comments && formik2.errors.comments} />

                                        </Box>





                                    </CardContent>
                                </Card>

                            </Box>


                        </Box>


                        {/* <MaterialUIPickers></MaterialUIPickers> */}
                    </div>


                </Box>

                <Box sx={{ m: 1, p: 1, display: 'flex', justifyContent: 'center' }}>
                    {/* <Box sx={{ display: 'flex', justifyContent: 'space-evenly', width: '5%' }}>
                        <IconButton sx={{ float: 'right' }} onClick={(e) => addRow(e, 0)}>
                            <AddCircleOutlineIcon></AddCircleOutlineIcon>
                        </IconButton>
                    </Box> */}
                    {/* <Button variant='contained' sx={{ m: 1 }} onClick={formik2.handleSubmit}>Save</Button> */}
                    <Button variant='contained' sx={{ m: 1 }} onClick={formik2.handleSubmit}>Save</Button>
                    <Button variant='contained' sx={{ m: 1 }} onClick={toggleDrawer(anchor, false, false)}>Cancel</Button>
                    {/* <Box sx={{ display: 'flex', justifyContent: 'space-evenly', width: '5%' }}>
                        <IconButton sx={{ float: 'right' }} onClick={(e) => removeRow(e, 0)}>
                            <RemoveCircleOutlineIcon></RemoveCircleOutlineIcon>
                        </IconButton>
                    </Box> */}
                </Box>

            </Box>
        </form>

    );

    const formik1 = useFormik({

        initialValues: {
            emailAddress: '',
            purchaseOrder: '',
            salesOrder: '',
            shipperName: '',
            origin: '',
            pickUpDate: '',
            originAddressLine1: '',
            originAddressLine2: '',
            originZipcode: '',
            originCity: '',
            originState: '',
            originContact: '',
            originPhone: '',
            originNotes: '',
            dnowPlantCode: '',
            destination: '',
            deliveryDate: '',
            destinationAddressLine1: '',
            destinationAddressLine2: '',
            destinationZipcode: '',
            destinationCity: '',
            destinationState: '',
            destinationContact: '',
            destinationPhone: '',
            destinationNotes: '',
            accessorials: multipleAccessorials,
            thu: 0,
            netWeight: 0,
            stackable: false,
            packageType1: packageType1,
            trailerSpaceRequired: 0
        },
        validationSchema: validateSchema1,
        validateOnChange: false,
        // validateOnBlur: false,
        onSubmit: (values) => {
            console.log("Form on submit");
            values['accessorials'] = multipleAccessorials.toString().replace(/,/g, ';');
            values['originZipcode'] = values['originZipcode'].toString().replace(/ /g, '');
            values['destinationZipcode'] = values['destinationZipcode'].toString().replace(/ /g, '');
            console.log('default delivery date: ', values['deliveryDate']);
            console.log('delivery date length', values['deliveryDate'].length)
            // values['pickUpDate'] = values['pickUpDate'] + 'T09:00:00'; //send this to sf 2022-02-16T00:00:00
            // if (values['deliveryDate'] !== ''){
            //     values['deliveryDate'] = values['deliveryDate'] + 'T09:00:00';
            // }

            formik1.touched.originZipcode = '';
            formik1.errors.originZipcode = false;
            formik1.touched.destinationZipcode = '';
            formik1.errors.destinationZipcode = false;


            var validLoads = true;

            let dtDiff = (new Date(values['deliveryDate']) - new Date(values['pickUpDate'])) / 3600000;
            console.log(dtDiff);
            if (values['pickUpDate'].length !== 10) {
                console.log('pickupdate error')
                validLoads = false;
                setValidationFailure({ 'error': true, 'message': 'Date should be in the MM/DD/YYYY or DD/MM/YYYY format.' })
            }
            if (!(values['deliveryDate'].length === 10 || values['deliveryDate'].length === 0)) {
                console.log("Delivery date error")
                validLoads = false;
                setValidationFailure({ 'error': true, 'message': 'Date should be in the MM/DD/YYYY or DD/MM/YYYY format.' })
            }
            if ((!isNaN(values['originZipcode'][0]) && values['originZipcode'].length > 5) || values['originZipcode'].length > 6) {
                //isNaN('E') --- true
                console.log('originZipcode error')
                validLoads = false;
                formik1.touched.originZipcode = 'Origin Zipcode if starts with number should not exceed 5 characters';
                formik1.errors.originZipcode = true;
                // setOriginZipcodeError = true;
                setValidationFailure({ 'error': true, 'message': 'Origin Zipcode if starts with number should not exceed 5 characters' })
            }
            if (!isNaN(values['destinationZipcode'][0]) && values['destinationZipcode'].length > 5 || values['originZipcode'].length > 6) {
                //isNaN('E') --- true
                console.log('destinationZipcode error')
                validLoads = false;
                formik1.touched.destinationZipcode = 'Destination Zipcode if starts with number should not exceed 5 characters';
                formik1.errors.destinationZipcode = true;
                setValidationFailure({ 'error': true, 'message': 'Destination Zipcode if starts with number should not exceed 5 characters' })
            }



            if (dtDiff < 24) {
                console.log("error occurred in date validations");
                validLoads = false;
                setValidationFailure({ 'error': true, 'message': 'Delivery date should be greater than Pickup date. For same day delivery, please reach Angel.Orazo@dnow.com' })
            }

            // if(values['originZipcode'] == '77041' || values['originZipcode'] == '11386' || values['originZipcode'] == '11091'){
            //     console.log('originZipcode error')
            //     validLoads = false;
            //     formik1.touched.originZipcode = `Thank you for submitting your order using DNOW's shipping guide. To ensure a smooth delivery process, we kindly ask for your patience as we plan the shipment accordingly. You can expect to receive additional shipping instructions shortly.`;
            //     formik1.errors.originZipcode = true;
            //     setValidationFailure({ 'error': true, 'message': `Thank you for submitting your order using DNOW's shipping guide. To ensure a smooth delivery process, we kindly ask for your patience as we plan the shipment accordingly. You can expect to receive additional shipping instructions shortly.` })
            // }
            // else 
            if (loadsData.length === 0) {
                console.log("From loads mandatory potential bug place")
                validLoads = false;
                setValidationFailure({ 'error': true, 'message': 'Loads are mandatory to create shipment' })
            }
            else {
                // load validations
                for (let i = 0; i < loadsData.length; i++) {
                    values['netWeight'] = values['netWeight'] + loadsData[i]['weight'];
                    // if (loadsData[i]['productDescription'] === '') {
                    //     validLoads = false;
                    //     setValidationFailure({ 'error': true, 'message': "Product description is mandatory" });
                    // }
                    // // else if (loadsData[i]['qty'] == 0) {
                    // //     setValidationFailure({ 'error': true, 'message': "Quantity must be greater than 0" });
                    // // }
                    // else if (loadsData[i]['class'] === '--None--') {
                    //     validLoads = false;
                    //     setValidationFailure({ 'error': true, 'message': "You must enter a value for class" });
                    // }
                    // else if (loadsData[i]['packageType2'] === 0) {
                    //     validLoads = false;
                    //     setValidationFailure({ 'error': true, 'message': "You must enter a value for Package type" });
                    // }
                    // else if (loadsData[i]['weight'] === 0) {
                    //     validLoads = false;
                    //     setValidationFailure({ 'error': true, 'message': "Weight must be greater than 0" });
                    // }
                }
            }





            if (validLoads) {
                console.log("Submit data to Salesforce");

                // setSendingDataToSalesforce(true);
                setSendingDataToSalesforce2(true);

                let final_json = {
                    'shipmentData': values,
                    'loads': loadsData
                }
                console.log('final_json: ', final_json);



                axios.post('https://tmsfirst-tender.herokuapp.com/api/createShipment', final_json).then(

                    res => {
                        console.log('create shipment response: ', res);
                        if (res['data']['status'] === true) {
                            window.sessionStorage.setItem("ship_id", res['data']['ship_id']);
                            window.sessionStorage.setItem("shipmentInfo", JSON.stringify(final_json));
                            //setting shipment block to further block the user for these locations
                            //"59313","59270","58801","58601","58854","58852","58523","58626","58763","58847","58784","83113","82901","82923","82604","82716","82336","82633"
                            //&& values['originZipcode'].substring(0,2) == '77'
                            if (
                                // (values['destinationZipcode'] === '59313' || values['destinationZipcode'] === '59270' || values['destinationZipcode'] === '58801'
                                // ||
                                // values['destinationZipcode'] === '58601' || values['destinationZipcode'] === '58854' || values['destinationZipcode'] === '58852'
                                // ||
                                // values['destinationZipcode'] === '58523' || values['destinationZipcode'] === '58626' || values['destinationZipcode'] === '58763'
                                // ||
                                // values['destinationZipcode'] === '58847' || values['destinationZipcode'] === '58784'
                                // ) && (values['originZipcode'].substring(0,2) == '77')

                                (values['destinationZipcode'] === '59313' || values['destinationZipcode'] === '59270' || values['destinationZipcode'] === '58801'
                                ||
                                values['destinationZipcode'] === '58601' || values['destinationZipcode'] === '58854' || values['destinationZipcode'] === '58852'
                                ||



                                values['destinationZipcode'] === '58523' || values['destinationZipcode'] === '58626' || values['destinationZipcode'] === '58763'



                                ||



                                values['destinationZipcode'] === '58847' || values['destinationZipcode'] === '58784' || values['destinationZipcode'] === '82601'
                                ||
                                values['destinationZipcode'] === '82605' || values['destinationZipcode'] === '82609' || values['destinationZipcode'] === '82636'
                                ||
                                values['destinationZipcode'] === '82414' || values['destinationZipcode'] === '82604' || values['destinationZipcode'] === '80651'



                                ) && (values['originZipcode'].substring(0, 2) === '77')



                            ) {
                                window.sessionStorage.setItem("blockShipment", 'true')
                                axios.get('https://tmsfirst-tender.herokuapp.com/api/getShipmentName/' + res['data']['ship_id']).then(
                                    shipmentNameResult => {
                                        console.log("from getting shipment name: ", shipmentNameResult)
                                        if (shipmentNameResult['data']['status'] === true) {
                                            window.sessionStorage.setItem("shipmentName", shipmentNameResult['data']['shipmentName'])
                                        }
                                        else { 
                                            window.sessionStorage.setItem("shipmentName", "Restricted Shipment")
                                            
                                        }
                                        //calling getRates to gothrough SF process
                                        console.log("calling get rates");
                                        axios.post('https://tmsfirst-tender.herokuapp.com/api/triggerSFtoGetRates/' + res['data']['ship_id']).then(triggerResult=>{
                                            setShipId(res['data']['ship_id']);
                                            window.location.reload()
                                        })
                                        // setShipId(res['data']['ship_id']);
                                        // window.location.reload()
                                    }

                                ).catch(err => {
                                    console.log("error while getting shipment name: ", err)
                                    setShipId(res['data']['ship_id']);
                                    window.sessionStorage.setItem("shipmentName", "Restricted Shipment")
                                    window.location.reload()
                                })


                            }
                            else {
                                setShipId(res['data']['ship_id']);
                            }

                            // setSendingDataToSalesforce(false);
                        }
                        else {
                            setSendingDataToSalesforce2(false);
                            setValidationFailure({ 'error': true, 'message': res['data']['message'] });
                        }
                    }
                ).catch(err => {
                    console.log('err: ', err);
                    setSendingDataToSalesforce2(false);
                    setValidationFailure({ 'error': true, 'message': err.toString() })
                })

                formik1.values['pickUpDate'] = values['pickUpDate'].replace('T09:00:00', '');
                if (values['deliveryDate'] !== '') {
                    formik1.values['deliveryDate'] = values['deliveryDate'].replace('T09:00:00', '');
                }

            }

        }
    });

    const formik2 = useFormik({
        initialValues: {
            productDescription: '',
            qty: 0,
            length: 0,
            width: 0,
            height: 0,
            units: units,
            classs: classs,
            packageType2: packageType2,
            weight: 0,
            comments: ''
        },
        validationSchema: validateSchema2,
        validateOnChange: false,
        onSubmit: (values) => {
            console.log("From formik on submit")

            if (!editMode['status']) {
                let tempLoadsData = loadsData;
                tempLoadsData.push(values);
                setLoadsData(tempLoadsData);
                formik2.resetForm();
                setPackage2Type('');
                setClasss('');
                setUnits('in');
                setState({ bottom: false });
            }
            else {
                let tempLoadsData = loadsData;
                let tempLoadsIndiData = loadsData[editMode['idx']];
                tempLoadsData[editMode['idx']] = values;
                setLoadsData(tempLoadsData)
                formik2.resetForm();
                setPackage2Type('');
                setClasss('');
                setUnits('in');
                setState({ bottom: false });
                setEditMode({ 'status': false, 'idx': null });
            }
        }
    })

    const getDataForZipcode = (zipcode, type) => {


        if (zipcode.length >= 5) {
            console.log(zipcode);
            if (type === 'o') {
                setAutoComplete2Loading(true);
            }
            else {
                setAutoComplete3Loading(true);
            }



            let zipUrl = 'https://tmsfirst-tender.herokuapp.com/zipcode_nl/' + zipcode;  //77041
            axios.get(zipUrl).then(res => {
                setAutoComplete2Loading(false);
                setAutoComplete3Loading(false);
                if (res['status'] === 200 && res['data']['status'] === true) {
                    if (type === 'o') {
                        formik1.setFieldValue('originCity', res['data']['data'][0]['City']);
                        formik1.setFieldValue('originState', res['data']['data'][0]['State']);
                    }
                    else {
                        formik1.setFieldValue('destinationCity', res['data']['data'][0]['City']);
                        formik1.setFieldValue('destinationState', res['data']['data'][0]['State']);
                    }

                }
                else {
                    if (type === 'o') {
                        formik1.setFieldValue('originCity', '');
                        formik1.setFieldValue('originState', '');
                        setManualIntervention(true);
                    }
                    else {
                        formik1.setFieldValue('destinationCity', '');
                        formik1.setFieldValue('destinationState', '');
                        setManualIntervention2(true);
                    }

                }
            }).catch(err => {
                setAutoComplete2Loading(false);
                setAutoComplete3Loading(false);
            })
        }
    }



    return (
        !sendingDataToSalesforce ?
            <React.Fragment>
                {/* <CssBaseline /> */}
                <Container maxWidth='100%' key={'1'}>
                    <AppBar position="static" sx={{ boxShadow: 'none' }}>
                        <Toolbar style={{ background: '#FFFFFF' }}>
                            <Card sx={{ display: 'flex', boxShadow: 'none', alignItems: 'end' }}>
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{ bgcolor: '#ededed' }} aria-label="recipe">
                                            <img src={newShipment} style={{ height: '4vh' }} alt='Icon'></img>
                                        </Avatar>
                                        // 
                                    }
                                    title={shipmentName === '' ? "Shipment" : "Shipment Reference"}
                                    titleTypographyProps={{ style: { fontSize: '1rem' } }}
                                    subheaderTypographyProps={{ style: { fontSize: '1.4em', fontWeight: shipmentName === '' ? null : 'bold', color: shipmentName === '' ? null : 'rgb(159 139 140 / 67%)' } }}
                                    subheader={shipmentName === '' ? 'New Shipment' : shipmentName}
                                />
                            </Card>

                        </Toolbar>

                    </AppBar>

                    <form onSubmit={formik1.handleSubmit} noValidate>
                        <FormControl sx={{ bgcolor: '#FFFFFF', width: '100%' }}>
                            {(shipmentName !== '' && isShipmentRestricted !== true) ? <div style={{
                                textAlign: 'center', display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}>
                                <p style={{ width: '75%', textAlign: 'center', color: 'rgb(20 80 5)', paddingInline: '4rem', fontSize: '1.5em', fontWeight: '500' }}>{headerMsg}
                                    


                                </p>
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <span style={{ fontSize: '1.2em', width: '80%', display: 'flex', justifyContent: 'center', marginLeft: '17%' }}>
                                        {
                                            headerMsg !== 'Please wait while we fetch carrier status...'
                                                ?
                                                <p style={{ background: 'aliceblue', borderRadius: '0.5rem', padding: '1rem' }}> BOL copy and Labels have been sent to your email address provided below. You can also download using the link on the right<br></br> Contact <a href={'mailto:dnowvendorsupport@tmsforce.com?subject=Support regarding ' + shipmentName}>dnowvendorsupport@tmsforce.com </a>using Ref ({shipmentName}) for further assistance.</p>
                                                : null}
                                    </span>
                                    {
                                        headerMsg !== 'Please wait while we fetch carrier status...' ?
                                            <p style={{ width: '20%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: '1rem 2rem', fontSize: '2em' }}><a style={{ display: 'flex', textDecoration: 'none', color: '#e78a87' }} href={`https://dnow-tms.force.com/vendor/TF_VF_SR_PDF_BOL_SiteUser?srid=${shipId}`} target="_blank">Download BOL<FileDownloadIcon sx={{ width: '2em', height: '2em' }}></FileDownloadIcon></a></p>
                                            :
                                            null
                                    }
                                </div>
                                {/* a0G2M00001CH8zmUAD */}

                            </div> : null}

                            {isShipmentRestricted === true ? <div style={{
                                textAlign: 'center', display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}>
                                <p style={{ width: '75%', textAlign: 'center', color: 'rgb(20 80 5)', paddingInline: '4rem', fontSize: '1.5em', fontWeight: '500' }} dangerouslySetInnerHTML={{ __html: headerMsg }}>
                                    

                                </p>
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <span style={{ fontSize: '1.2em', width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        {
                                            headerMsg !== 'Please wait while we fetch carrier status...'
                                                ?
                                                <p style={{ background: 'aliceblue', borderRadius: '0.5rem', padding: '1rem' }}>
                                                    {/* DNOW logistics coordinator will reach out to you to provide specific details on how to proceed with your shipments.
                                                    <br></br>
                                                    We understand that this may change your business operations and we are committed to working with you to minimize any disruptions that may occur.
                                                    <br></br>We appreciate your understanding and flexibility during this time.
                                                    You can expect to receive additional shipping instructions shortly.
                                                    <br></br> */}
                                                    Important: A new bill of lading will be emailed after the local pick up has been scheduled.
                                                    <br></br>
                                                    For further support please reachout to <a href={'mailto:CorporateLogistics@dnow.com?subject=Support regarding ' + shipmentName}>CorporateLogistics@dnow.com</a></p>
                                                : null}
                                    </span>

                                </div>
                                {/* a0G2M00001CH8zmUAD */}

                            </div> : null}

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    m: 1
                                }}
                            >
                                <TextField sx={{ m: 1, "& .Mui-disabled": { WebkitTextFillColor: "black" } }} id="outlined-basic" label="Email Address" variant="outlined" size='small' name='emailAddress' value={formik1.values.emailAddress} onChange={formik1.handleChange} error={formik1.touched.emailAddress && Boolean(formik1.errors.emailAddress)} autoComplete={'off'} helperText={formik1.touched.emailAddress && formik1.errors.emailAddress} fullWidth required disabled={shipId !== null ? true : false} />
                                <TextField sx={{ m: 1, "& .Mui-disabled": { WebkitTextFillColor: "black" } }} id="outlined-basic" label="DNOW Purchase Order" variant="outlined" name='purchaseOrder' required size='small' value={formik1.values.purchaseOrder} onChange={formik1.handleChange} error={formik1.touched.purchaseOrder && Boolean(formik1.errors.purchaseOrder)} helperText={formik1.touched.purchaseOrder && formik1.errors.purchaseOrder} fullWidth autoComplete='off' disabled={shipId !== null ? true : false} />
                                <TextField sx={{ m: 1, "& .Mui-disabled": { WebkitTextFillColor: "black" } }} id="outlined-basic" label="Sales Order" value={formik1.values.salesOrder} name='salesOrder' variant="outlined" onChange={formik1.handleChange} autoComplete='off' error={formik1.touched.salesOrder && Boolean(formik1.errors.salesOrder)} helperText={formik1.touched.salesOrder && formik1.errors.salesOrder} size='small' fullWidth disabled={shipId !== null ? true : false} />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                <Box sx={{ width: '100%', m: 1 }}>
                                    <Typography sx={{ fontSize: 20, marginInlineStart: '14px' }} color="text.secondary" gutterBottom>
                                        Pickup Information
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <TextField
                                            sx={{ m: 1, "& .Mui-disabled": { WebkitTextFillColor: "black" } }}
                                            label="Pick Up Date"
                                            type="date"
                                            // defaultValue="2017-05-24T10:30"//2022-01-20T05:44:11.000+0000
                                            name='pickUpDate'
                                            size='small'
                                            required
                                            onChange={formik1.handleChange}
                                            value={formik1.values.pickUpDate}
                                            error={formik1.touched.pickUpDate && Boolean(formik1.errors.pickUpDate)}
                                            helperText={formik1.touched.pickUpDate && formik1.errors.pickUpDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={shipId !== null ? true : false}
                                        />

                                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                            label="Material Date Picker"
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            />
                                        </MuiPickersUtilsProvider> */}

                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            {/* <TextField sx={{ m: 1, width: '100%' }} id="outlined-basic" label="Shipper Name" name='shipperName' variant="outlined" size='small' value={formik1.values.shipperName} onChange={formik1.handleChange} error={formik1.touched.shipperName && Boolean(formik1.errors.shipperName)} autoComplete={'off'} helperText={formik1.touched.shipperName && formik1.errors.shipperName} disabled={shipId !== null ? true : false}/> */}

                                            <TextField sx={{ m: 1, width: '100%', "& .Mui-disabled": { WebkitTextFillColor: "black" } }} id="outlined-basic" label="Shipper Name" name='origin' required variant="outlined" size='small' value={formik1.values.origin} onChange={formik1.handleChange} error={formik1.touched.origin && Boolean(formik1.errors.origin)} autoComplete={'off'} helperText={formik1.touched.origin && formik1.errors.origin} disabled={shipId !== null ? true : false} />

                                        </Box>
                                        <TextField sx={{ m: 1, "& .Mui-disabled": { WebkitTextFillColor: "black" } }} id="outlined-basic" label="Origin Address Line 1" name='originAddressLine1' required variant="outlined" size='small' value={formik1.values.originAddressLine1} onChange={formik1.handleChange} error={formik1.touched.originAddressLine1 && Boolean(formik1.errors.originAddressLine1)} autoComplete={'off'} helperText={formik1.touched.originAddressLine1 && formik1.errors.originAddressLine1} disabled={shipId !== null ? true : false} />
                                        <TextField sx={{ m: 1, "& .Mui-disabled": { WebkitTextFillColor: "black" } }} id="outlined-basic" label="Origin Address Line 2" name='originAddressLine2' variant="outlined" size='small' value={formik1.values.originAddressLine2} onChange={formik1.handleChange} autoComplete={'off'} disabled={shipId !== null ? true : false} error={formik1.touched.originAddressLine2 && Boolean(formik1.errors.originAddressLine2)} helperText={formik1.touched.originAddressLine2 && formik1.errors.originAddressLine2} />
                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                                            <TextField sx={{ m: 1, "& .Mui-disabled": { WebkitTextFillColor: "black" } }} id="originZipcode" label="Origin Zipcode" name='originZipcode' required variant="outlined" size='small' value={formik1.values.originZipcode} onChange={
                                                (e) => {
                                                    formik1.handleChange(e);
                                                    getDataForZipcode(e['target'].value, 'o');
                                                }

                                            } error={formik1.touched.originZipcode && Boolean(formik1.errors.originZipcode)} autoComplete={'off'} helperText={formik1.touched.originZipcode && formik1.errors.originZipcode} InputProps={{
                                                endAdornment: (<InputAdornment position='end'>
                                                    {autoComplete2Loading ? <CircularProgress size={15}></CircularProgress> : null}
                                                </InputAdornment>)
                                            }} disabled={shipId !== null ? true : false} />
                                            <TextField sx={{ m: 1, "& .Mui-disabled": { WebkitTextFillColor: "black" } }} id="originCity" label="Origin City" name='originCity' required variant="outlined" size='small' value={formik1.values.originCity} onChange={formik1.handleChange} error={formik1.touched.originCity && Boolean(formik1.errors.originCity)} autoComplete={'off'} helperText={formik1.touched.originCity && formik1.errors.originCity} disabled={shipId !== null ? true : false} className={classes.root} />   {/* disabled={shipId !== null || !manualIntevention ? true : false} */}

                                            <TextField sx={{ m: 1, "& .Mui-disabled": { WebkitTextFillColor: "black" } }} id="originState" label="Origin State" name='originState' required variant="outlined" size='small' value={formik1.values.originState} onChange={formik1.handleChange} error={formik1.touched.originState && Boolean(formik1.errors.originState)} autoComplete={'off'} helperText={formik1.touched.originState && formik1.errors.originState} disabled={shipId !== null ? true : false} />
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <TextField sx={{ m: 1, width: '100%', "& .Mui-disabled": { WebkitTextFillColor: "black" } }} id="outlined-basic" label="Origin Contact" name='originContact' required variant="outlined" size='small' value={formik1.values.originContact} onChange={formik1.handleChange} error={formik1.touched.originContact && Boolean(formik1.errors.originContact)} autoComplete={'off'} helperText={formik1.touched.originContact && formik1.errors.originContact} disabled={shipId !== null ? true : false} />
                                            <TextField sx={{ m: 1, width: '100%', "& .Mui-disabled": { WebkitTextFillColor: "black" } }} id="outlined-basic" label="Origin Phone" name='originPhone' required variant="outlined" size='small' value={formik1.values.originPhone} onChange={formik1.handleChange} error={formik1.touched.originPhone && Boolean(formik1.errors.originPhone)} autoComplete={'off'} helperText={formik1.touched.originPhone && formik1.errors.originPhone} disabled={shipId !== null ? true : false} />
                                        </Box>
                                        <TextField sx={{ m: 1, "& .Mui-disabled": { WebkitTextFillColor: "black" } }} id="outlined-basic" label="Origin Notes" name='originNotes' variant="outlined" size='small' multiline value={formik1.values.originNotes} onChange={formik1.handleChange} disabled={shipId !== null ? true : false} error={formik1.touched.originNotes && Boolean(formik1.errors.originNotes)} helperText={formik1.touched.originNotes && formik1.errors.originNotes} />


                                    </Box>
                                </Box>
                                <Box sx={{ width: '100%', m: 1 }}>
                                    <Typography sx={{ fontSize: 20, marginInlineStart: '14px' }} color="text.secondary" gutterBottom>
                                        Drop Information
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <TextField
                                            sx={{ m: 1, "& .Mui-disabled": { WebkitTextFillColor: "black" } }}
                                            label="Delivery Date"
                                            name='deliveryDate'
                                            required
                                            type="date"
                                            // defaultValue="2017-05-24T10:30"
                                            onChange={formik1.handleChange}
                                            value={formik1.values.deliveryDate}
                                            error={formik1.touched.deliveryDate && Boolean(formik1.errors.deliveryDate)}
                                            helperText={formik1.touched.deliveryDate && formik1.errors.deliveryDate}
                                            size='small'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={shipId !== null ? true : false}
                                        />


                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Autocomplete
                                                freeSolo
                                                id="dnowPlantCode"
                                                sx={{ m: 1, width: '100%', "& .Mui-disabled": { WebkitTextFillColor: "black" } }}
                                                size='small'
                                                open={openAuto1}
                                                onOpen={() => {
                                                    setOpenAuto1(true);
                                                }}
                                                onClose={() => {
                                                    setOpenAuto1(false);
                                                }}
                                                onChange={(event, value) => {
                                                    formik1.values.dnowPlantCode = value.Id;
                                                    formik1.values.destination = value.Address_Line_1__c;
                                                    formik1.values.destinationAddressLine1 = value.Address_Line_2__c;
                                                    formik1.values.destinationAddressLine2 = value.Address_Line_3__c;
                                                    formik1.values.destinationZipcode = value.Zip_Code__c;
                                                    formik1.values.destinationCity = value.City__c;
                                                    formik1.values.destinationState = value.Province__c;
                                                    formik1.values.destinationContact = value.Contact__c;
                                                    formik1.values.destinationPhone = value.Phone__c;
                                                }}
                                                isOptionEqualToValue={(option, value) => option.Name === value.Name}
                                                getOptionLabel={(option) => option.Name}
                                                options={autoOptions1}
                                                loading={autoComplete1Loading}
                                                disabled={shipId !== null ? true : false}
                                                renderOption={(props, option) => (
                                                    <div {...props} key={option.Id}>
                                                        <Card sx={{ m: 0.5 }} key={option.Id}>
                                                            <CardHeader
                                                                title={option.Name}
                                                                titleTypographyProps={{ style: { fontSize: '1rem' } }}
                                                                subheaderTypographyProps={{ style: { fontSize: '0.7rem' } }}
                                                                subheader={option.Address_Line_1__c + ' ' +
                                                                    option.Address_Line_2__c + ' ' +
                                                                    option.Address_Line_3__c + ' ' +
                                                                    option.City__c + ' ' + option.Province__c + ' ' + option.Phone__c
                                                                }
                                                            />
                                                        </Card>
                                                    </div>

                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="DNOW Plant Location"
                                                        value={formik1.values.dnowPlantCode}
                                                        sx={{ "& .Mui-disabled": { WebkitTextFillColor: "black" } }}
                                                        onChange={formik1.handleChange}
                                                        disabled={shipId !== null ? true : false}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {autoComplete1Loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {/* {params.InputProps.endAdornment} */}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <TextField sx={{ m: 1, width: '100%', "& .Mui-disabled": { WebkitTextFillColor: "black" } }} id="outlined-basic" label="Destination" required name='destination' variant="outlined" size='small' value={formik1.values.destination} onChange={formik1.handleChange} error={formik1.touched.destination && Boolean(formik1.errors.destination)} autoComplete={'off'} helperText={formik1.touched.destination && formik1.errors.destination} disabled={shipId !== null ? true : false} />

                                        </Box>
                                        <TextField sx={{ m: 1, "& .Mui-disabled": { WebkitTextFillColor: "black" } }} id="destinationAddressLine1" label="Destination Address Line 1" value={formik1.values.destinationAddressLine1} onChange={formik1.handleChange} error={formik1.touched.destinationAddressLine1 && Boolean(formik1.errors.destinationAddressLine1)} autoComplete={'off'} helperText={formik1.touched.destinationAddressLine1 && formik1.errors.destinationAddressLine1} required variant="outlined" size='small' disabled={shipId !== null ? true : false} />
                                        <TextField sx={{ m: 1, "& .Mui-disabled": { WebkitTextFillColor: "black" } }} id="destinationAddressLine2" label="Destination Address Line 2" autoComplete={'off'} name='destinationAddressLine2' value={formik1.values.destinationAddressLine2} onChange={formik1.handleChange} variant="outlined" size='small' disabled={shipId !== null ? true : false} error={formik1.touched.destinationAddressLine2 && Boolean(formik1.errors.destinationAddressLine2)} helperText={formik1.touched.destinationAddressLine2 && formik1.errors.destinationAddressLine2} />
                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <TextField sx={{ m: 1, "& .Mui-disabled": { WebkitTextFillColor: "black" } }} id="destinationZipcode" label="Destination Zipcode" name='destinationZipcode' required variant="outlined" size='small' value={formik1.values.destinationZipcode} onChange={
                                                (e) => {
                                                    formik1.handleChange(e);
                                                    getDataForZipcode(e['target'].value, 'd');
                                                }

                                            } error={formik1.touched.destinationZipcode && Boolean(formik1.errors.destinationZipcode)} autoComplete={'off'} helperText={formik1.touched.destinationZipcode && formik1.errors.destinationZipcode} InputProps={{
                                                endAdornment: (<InputAdornment position='end'>
                                                    {autoComplete3Loading ? <CircularProgress size={15}></CircularProgress> : null}
                                                </InputAdornment>)
                                            }} disabled={shipId !== null ? true : false} />

                                            {/* <TextField sx={{ m: 1, "& .Mui-disabled": { WebkitTextFillColor: "black" } }} id="outlined-basic" label="Destination Zipcode" name='destinationZipcode' required variant="outlined" size='small' value={formik1.values.destinationZipcode} onChange={formik1.handleChange} error={formik1.touched.destinationZipcode && Boolean(formik1.errors.destinationZipcode)} autoComplete={'off'} helperText={formik1.touched.destinationZipcode && formik1.errors.destinationZipcode} disabled={shipId !== null ? true : false} /> */}
                                            <TextField sx={{ m: 1, "& .Mui-disabled": { WebkitTextFillColor: "black" } }} id="destinationCity" label="Destination City" name='destinationCity' required variant="outlined" size='small' value={formik1.values.destinationCity} onChange={formik1.handleChange} error={formik1.touched.destinationCity && Boolean(formik1.errors.destinationCity)} autoComplete={'off'} helperText={formik1.touched.destinationCity && formik1.errors.destinationCity} disabled={shipId !== null ? true : false} className={classes.root} />
                                            <TextField sx={{ m: 1, "& .Mui-disabled": { WebkitTextFillColor: "black" } }} id="destinationState" label="Destination State" name='destinationState' required variant="outlined" size='small' value={formik1.values.destinationState} onChange={formik1.handleChange} error={formik1.touched.destinationState && Boolean(formik1.errors.destinationState)} autoComplete={'off'} helperText={formik1.touched.destinationState && formik1.errors.destinationState} disabled={shipId !== null ? true : false} />
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <TextField sx={{ m: 1, width: '100%', "& .Mui-disabled": { WebkitTextFillColor: "black" } }} id="outlined-basic" label="Destination Contact" name='destinationContact' required variant="outlined" size='small' value={formik1.values.destinationContact} onChange={formik1.handleChange} error={formik1.touched.destinationContact && Boolean(formik1.errors.destinationContact)} autoComplete={'off'} helperText={formik1.touched.destinationContact && formik1.errors.destinationContact} disabled={shipId !== null ? true : false} />
                                            <TextField sx={{ m: 1, width: '100%', "& .Mui-disabled": { WebkitTextFillColor: "black" } }} id="outlined-basic" label="Destination Phone" name='destinationPhone' required variant="outlined" size='small' value={formik1.values.destinationPhone} onChange={formik1.handleChange} error={formik1.touched.destinationPhone && Boolean(formik1.errors.destinationPhone)} autoComplete={'off'} helperText={formik1.touched.destinationPhone && formik1.errors.destinationPhone} disabled={shipId !== null ? true : false} />
                                        </Box>
                                        <TextField sx={{ m: 1, "& .Mui-disabled": { WebkitTextFillColor: "black" } }} id="outlined-basic" label="Destination Notes" name='destinationNotes' variant="outlined" size='small' multiline value={formik1.values.destinationNotes} onChange={formik1.handleChange} disabled={shipId !== null ? true : false} error={formik1.touched.destinationNotes && Boolean(formik1.errors.destinationNotes)} helperText={formik1.touched.destinationNotes && formik1.errors.destinationNotes} />

                                    </Box>
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', m: 1 }}>
                                <Box sx={{ width: '30%', m: 1 }}>
                                    <Box sx={{ width: '100%', height: '100%' }}>
                                        <div style={{ height: '100%' }}>
                                            <FormControl sx={{ m: 2, width: '100%', height: '100%', "& .Mui-disabled": { WebkitTextFillColor: "black" } }} size='large' error={Boolean(formik1.errors.accessorials)}>
                                                <InputLabel id="multipleAccessorialsLabel">Accessorials</InputLabel>
                                                <Select
                                                    sx={{ height: '75%', "& .Mui-disabled": { WebkitTextFillColor: "black" } }}
                                                    labelId="multipleAccessorialsLabel"
                                                    id="multipleAccessorials"
                                                    name='accessorials'
                                                    multiple
                                                    value={multipleAccessorials}
                                                    onChange={handleAccesorialChange}
                                                    disabled={shipId !== null ? true : false}


                                                    input={<OutlinedInput id="select-multiple-chip" label="Accessorials" />}

                                                    renderValue={(selected) => (
                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', height: '75%', gap: 0.5 }}>
                                                            {selected.map((value) => (
                                                                <Chip key={value} label={value} />
                                                            ))}
                                                        </Box>

                                                    )}
                                                    MenuProps={MenuProps}
                                                >
                                                    {accessorialss.map((name) => (
                                                        <MenuItem
                                                            key={name}
                                                            value={name}
                                                            style={getStyles(name, multipleAccessorials, theme)}
                                                        >
                                                            {name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Box>
                                </Box>
                                <Box sx={{ width: '45%', m: 1, p: 1 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Box sx={{ justifyContent: 'space-evenly' }}>
                                            <TextField sx={{ m: 1, width: '30%', "& .Mui-disabled": { WebkitTextFillColor: "black" } }} id="outlined-basic" label="Package Count" type={'number'} name='thu' required variant="outlined" size='small' value={formik1.values.thu} onChange={formik1.handleChange} error={formik1.touched.thu && Boolean(formik1.errors.thu)} autoComplete={'off'} helperText={formik1.touched.thu && formik1.errors.thu} disabled={shipId !== null ? true : false} />

                                            {packageType1 === 'Standard PLT' ?
                                                <FormControlLabel sx={{ m: 1, width: '30%' }} control={<Switch defaultChecked={formik1.values.stackable} />} name='stackable' label="Stackable" value={formik1.values.stackable} onChange={formik1.handleChange} disabled={shipId !== null ? true : false} /> : null}

                                        </Box>
                                        <Box>
                                            <FormControl sx={{ m: 1, width: '30%' }} size='small' error={Boolean(formik1.errors.packageType1)}>

                                                <InputLabel id="packageType1Label">Package Type</InputLabel>
                                                <Select
                                                    labelId="packageType1Label"
                                                    id="packageType1"
                                                    value={packageType1}
                                                    required
                                                    sx={{ "& .Mui-disabled": { WebkitTextFillColor: "black" } }}
                                                    // value={formik1.values.packageType1}// value={packageType1}
                                                    label="packageType1"
                                                    disabled={shipId !== null ? true : false}
                                                    onChange={(e) => {
                                                        setPackage1Type(e['target'].value);
                                                        formik1.values.packageType1 = e['target'].value;
                                                        if (e['target'].value === 'Standard PLT') {
                                                            formik1.values.trailerSpaceRequired = 0;
                                                        } else {
                                                            formik1.values.stackable = false;

                                                        }

                                                    }}
                                                // onChange={formik1.handleChange('packageType1')}

                                                >
                                                    <MenuItem value={'Standard PLT'}>Standard PLT</MenuItem>
                                                    <MenuItem value={'Non Standard PLT'}>Non Standard PLT</MenuItem>
                                                    <MenuItem value={'Other'}>Other</MenuItem>
                                                </Select>
                                                <FormHelperText>{formik1.errors.packageType1}</FormHelperText>
                                            </FormControl>

                                            {packageType1 === 'Non Standard PLT' || packageType1 === 'Other' ?
                                                <TextField sx={{ m: 1, width: '30%', "& .Mui-disabled": { WebkitTextFillColor: "black" } }} id="trailerSpaceRequired" label="Trailer Space Required" helperText='linear feet' onChange={formik1.handleChange} value={formik1.values.trailerSpaceRequired} name='trailerSpaceRequired' type={'number'} variant="outlined" size='small' autoComplete='off' disabled={shipId !== null ? true : false} /> : null}
                                            <Button id='submitMainData' type='submit' variant='contained' sx={{ m: 1, display: 'none' }}>Submit Main Data</Button>

                                        </Box>
                                    </Box>

                                </Box>
                                <Box sx={{ width: '25%', m: 1, p: 1 }}>
                                    {<Card variant='outlined'>
                                        <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

                                            <Typography variant="h2" sx={{ textAlign: 'center' }} component="div">


                                                {/* {[1, 2, 3].reduce((sum, i) => {
                                                return sum+i;
                                            })} */}

                                                {/* {
                                                [{'a': 1, 'b':2}, {'a': 2, 'b': 6}, {'a': 4, 'b': 10}].reduce((sum, i) => {
                                                    console.log(sum, i);
                                                    return sum['a']+i['a'];
                                                })
                                            } */}

                                                {/* {
                                                    loadsData.length === 0 ? '-'
                                                        :
                                                        (
                                                            loadsData.length > 1 ? loadsData.reduce((sum, i) => {
                                                                console.log('sum: ', sum);
                                                                console.log('i: ', i);
                                                                console.log('Total: ',sum['weight'] + i['weight']);
                                                                return sum['weight'] + i['weight'];
                                                            }) : loadsData[0]['weight']
                                                        )
                                                } */}


                                                {loadsWeight}


                                            </Typography>
                                            <Typography sx={{ fontSize: 14, textAlign: 'center' }} color="text.secondary" gutterBottom>
                                                Total Weight (lbs)
                                            </Typography>
                                        </CardContent>

                                    </Card>}

                                </Box>
                            </Box>

                            <div style={{ backgroundColor: '#000000' }}>
                                {['bottom'].map((anchor) => (
                                    <React.Fragment key={anchor}>
                                        <Drawer
                                            sx={{ bgColor: '#000000' }}
                                            anchor={anchor}
                                            open={state[anchor]}
                                            onClose={toggleDrawer(anchor, false, false)}
                                        >
                                            {list(anchor)}


                                        </Drawer>
                                    </React.Fragment>
                                ))}
                            </div>


                        </FormControl>
                    </form>



                </Container>
                <br></br>
                <Container maxWidth='100%' key={'2'}>
                    {loadsData.length !== 0 ? <TableContainer component={Paper}>
                        <Toolbar>
                            <Typography sx={{ fontSize: 20, marginBottom: 0 }} color="text.secondary" gutterBottom>
                                Load Information
                            </Typography>
                        </Toolbar>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>

                                    <TableCell align='left'>Product Description</TableCell>
                                    <TableCell align="center">Quantity</TableCell>
                                    <TableCell align="center">Dimensions&nbsp;(L X W X H)</TableCell>
                                    <TableCell align="center">Class</TableCell>
                                    <TableCell align="center">PackageType</TableCell>
                                    <TableCell align="center">Weight&nbsp;(lbs)</TableCell>
                                    <TableCell align="center">Comments</TableCell>
                                    {shipId === null ? <TableCell align='left'></TableCell> : null}
                                </TableRow>
                            </TableHead>
                            <TableBody key={'tbody1'}>

                                {loadsData.map((row, idx) => (

                                    <TableRow
                                        key={idx}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >

                                        <TableCell align='left'>
                                            {row['productDescription'] === '' ? '-' : row['productDescription']}
                                        </TableCell>
                                        <TableCell align="center">{row['qty'] === 0 ? '-' : row['qty']}</TableCell>
                                        <TableCell align="center">{row['length'] === 0 ? '-' : row['length'] + ' X ' + row['width'] + ' X ' + row['height'] + ' ' + row['units']}</TableCell>
                                        <TableCell align="center">{row['classs'] === '--None--' ? '-' : row['classs']}</TableCell>
                                        <TableCell align="center">{row['packageType2'] === '--None--' ? '-' : row['packageType2']}</TableCell>
                                        <TableCell align="center">{row['weight'] === 0 ? '-' : row['weight']}</TableCell>
                                        <TableCell align="center">{row['comments'] === '' ? '-' : row['comments']}</TableCell>
                                        {shipId === null ?
                                            <TableCell align='left'>
                                                <IconButton onClick={(e) => { editRow(e, idx); }}><EditIcon></EditIcon></IconButton>
                                                <IconButton onClick={(e) => { removeRow(e, idx); }}><DeleteIcon sx={{ color: '#ed3833' }}></DeleteIcon></IconButton>
                                            </TableCell> : null}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <Box sx={{ m: 1, p: 0, display: 'flex', justifyContent: 'center' }}>


                            <div>
                                {shipId === null ? <LoadingButton loading={sendingDataToSalesforce2} onClick={toggleDrawer('bottom', true, false)} variant='contained' sx={{ m: 0, marginInline: 1 }}>Add Loads</LoadingButton> : null}

                            </div>

                            {
                                shipId === null ?
                                    <LoadingButton loading={sendingDataToSalesforce2} onClick={(e) => {
                                        document.getElementById('submitMainData').click();

                                    }} variant='contained' sx={{ m: 0, marginInline: 1 }}>Save</LoadingButton>
                                    :
                                    //added for hiding get rates if used with below pincodes
                                    (
                                        shipmentName === ''
                                        // && 
                                        // (
                                        //     JSON.parse(sessionStorage.getItem('shipmentInfo'))['shipmentData']['destinationZipcode'] !== '77041'
                                        //     ||
                                        //     JSON.parse(sessionStorage.getItem('shipmentInfo'))['shipmentData']['destinationZipcode'] !== '11386'
                                        //     ||
                                        //     JSON.parse(sessionStorage.getItem('shipmentInfo'))['shipmentData']['destinationZipcode'] !== '11091'
                                        // )
                                    )
                                        ?
                                        <LoadingButton loading={false} onClick={(e) => {
                                            setSendingDataToSalesforce(true);
                                            let ship_id = window.sessionStorage.getItem('ship_id');
                                            axios.post('https://tmsfirst-tender.herokuapp.com/api/triggerSFtoGetRates/' + ship_id).then(
                                                res2 => {
                                                    if (res2['data']['Status'] === "True") {
                                                        window.sessionStorage.setItem('carrierLabel', res2['data']['carrierLabel'].toString())
                                                        window.sessionStorage.setItem('needToRedirect', 'true');
                                                        let path = `/details`;
                                                        setTimeout(() => {
                                                            navigate(path);
                                                        }, 2500);
                                                    }
                                                    else {
                                                        setSendingDataToSalesforce(false);
                                                        setValidationFailure({ 'error': true, 'message': res2['data']['Message'] })
                                                    }
                                                }
                                            ).catch(err => {
                                                console.log('err: ', err);
                                                setSendingDataToSalesforce(false);
                                                setValidationFailure({ 'error': true, 'message': err.toString() })
                                            })

                                        }} variant='contained' sx={{ m: 0, marginInline: 1 }}>Get Rates</LoadingButton> : null
                            }
                            <Snackbar open={ValidationFailure['error']} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} sx={{ m: 2 }} autoHideDuration={6000} onClose={(event, reason) => {
                                if (reason === 'clickaway') {
                                    return;
                                }

                                setValidationFailure({ 'error': false, 'message': '' });
                            }}>
                                <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
                                    {ValidationFailure['message']}
                                </Alert>
                            </Snackbar>


                        </Box>


                    </TableContainer>
                        :
                        <TableContainer component={Paper}>
                            <Toolbar>
                                <Typography sx={{ fontSize: 20, marginBottom: 0 }} color="text.secondary" gutterBottom>
                                    Load Information
                                </Typography>
                            </Toolbar>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='left'>Product Description</TableCell>
                                        <TableCell align="left">Quantity</TableCell>
                                        <TableCell align="left">Dimensions&nbsp;(L X W X H)</TableCell>
                                        <TableCell align="left">Class</TableCell>
                                        <TableCell align="left">PackageType</TableCell>
                                        <TableCell align="left">Weight&nbsp;(lbs)</TableCell>
                                        <TableCell align="left">Comments</TableCell>
                                        <TableCell align='left'></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody key={'tbody2'}>

                                    <TableRow
                                    // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align='left'>-</TableCell>
                                        <TableCell align='left'>-</TableCell>
                                        <TableCell align='left'>-</TableCell>
                                        <TableCell align='left'>-</TableCell>
                                        <TableCell align='left'>-</TableCell>
                                        <TableCell align='left'>-</TableCell>
                                        <TableCell align='left'>-</TableCell>
                                        <TableCell align='left'></TableCell>

                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Box sx={{ m: 1, p: 0, display: 'flex', justifyContent: 'center' }}>


                                {/* <Button id='toggleButton' onClick={toggleDrawer('bottom', true, false)} variant='contained' sx={{ m: 1 }}>Add Loads</Button> */}

                                <div>
                                    {shipId === null ? <LoadingButton loading={sendingDataToSalesforce2} onClick={toggleDrawer('bottom', true, false)} variant='contained' sx={{ m: 0, marginInline: 1 }}>Add Loads</LoadingButton> : null}

                                </div>

                                {
                                    shipId === null ?
                                        <LoadingButton loading={sendingDataToSalesforce2} onClick={(e) => {
                                            document.getElementById('submitMainData').click();

                                        }} variant='contained' sx={{ m: 0, marginInline: 1 }}>Save</LoadingButton>
                                        :
                                        <LoadingButton loading={false} onClick={(e) => {
                                            setSendingDataToSalesforce(true);
                                            let ship_id = window.sessionStorage.getItem('ship_id');
                                            axios.post('https://tmsfirst-tender.herokuapp.com/api/triggerSFtoGetRates/' + ship_id).then(
                                                res2 => {
                                                    if (res2['data']['Status'] === "True") {
                                                        window.sessionStorage.setItem('carrierLabel', res2['data']['carrierLabel'].toString());
                                                        console.log("Waiting for 3 seconds and redirecting");
                                                        setTimeout(() => {
                                                            let path = `/details`;
                                                            navigate(path);
                                                        }, 3000);
                                                    }
                                                    else {
                                                        setSendingDataToSalesforce(false);
                                                        setValidationFailure({ 'error': true, 'message': res2['data']['Message'] })
                                                    }
                                                }
                                            ).catch(err => {
                                                console.log('err: ', err);
                                                setSendingDataToSalesforce(false);
                                                setValidationFailure({ 'error': true, 'message': err.toString() })
                                            })

                                        }} variant='contained' sx={{ m: 0, marginInline: 1 }}>Get Rates</LoadingButton>
                                }
                                <Snackbar open={ValidationFailure['error']} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} sx={{ m: 2 }} autoHideDuration={6000} onClose={(event, reason) => {
                                    if (reason === 'clickaway') {
                                        return;
                                    }

                                    setValidationFailure({ 'error': false, 'message': '' });
                                }}>
                                    <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
                                        {ValidationFailure['message']}
                                    </Alert>
                                </Snackbar>


                                {/* <Snackbar open={true}>
                                    <Alert severity="success" sx={{ width: '100%' }}>
                                    This is a big longer code of line test which needs to be processed
                                    </Alert>
                                </Snackbar> */}


                            </Box>


                        </TableContainer>
                    }

                </Container>
                <br></br>
                <Dialog open={sendingDataToSalesforce} sx={{ boxShadow: 'none' }}>
                    <Box id='test' sx={{ backgroundColor: '#7f7f7f', boxShadow: 'none' }}>
                        <CircularProgress></CircularProgress>
                    </Box>
                </Dialog>



            </React.Fragment>
            :
            <LoadingAnimations></LoadingAnimations>

        // <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>

        //     <img src={truck1} alt='truck'></img>  
        // </div>



    );
}



